const NAME_KEY = "name";
const KIND_KEY = "type";
const PRICE_KEY = "price";
const SHOW_KEY = "value";
const DIETARY_PREFERENCES_KEY = "dietaryPreferences";
const DESCRIPTION_KEY = "description";
const PRODUCT_KIND = "product";
const LOCALE_FOR_PRICE = "nl-NL";

class MappingOrchestrator {
  #printessCtx;
  #sourceMenu;
  #sourceMappings;

  // Source mappings are single source to target.
  // They are given as multiple sources to target, but we simplify here.
  // Only source mappings with a target table that we can find, and source items that we can find, are built.
  static buildSourceMappings(printessCtx, sourceMenu) {
    const rawMappings = printessCtx.metadata("source_mappings");
    if (rawMappings.length === 0) return [];

    return rawMappings.flatMap((mapping) => {
      const targetTable = PrintessTable.find(printessCtx, mapping.target);
      if (!targetTable) return [];

      return mapping.source.reduce((acc, sourceName) => {
        const menuItems = sourceMenu.whereCategory(sourceName);

        if (menuItems.length === 0) return acc;

        return [
          ...acc,
          new SourceMapping(
            sourceName,
            menuItems,
            targetTable,
            mapping.options || {},
          ),
        ];
      }, []);
    });
  }

  constructor(printessCtx) {
    this.#printessCtx = printessCtx;
    this.#sourceMenu = SourceMenu.fromRaw(
      this.#printessCtx.sourceMenuDataValue,
    );
    this.#sourceMappings = MappingOrchestrator.buildSourceMappings(
      this.#printessCtx,
      this.#sourceMenu,
    );
  }

  perform() {
    this.#sourceMappings.forEach((sourceMapping) => {
      sourceMapping.perform();
    });
  }
}

// Simple menu item wrapper to keep track of which items have been added already
// during a mapping.
class SourceMappingCandidate {
  constructor(menuItem) {
    this.menuItem = menuItem;
    this.isAdded = false;
  }

  added() {
    this.isAdded = true;
  }
}

class SourceMapping {
  #sourceName;
  #menuItems;
  #targetTable;
  #options;

  constructor(sourceName, menuItems, targetTable, options) {
    this.#sourceName = sourceName;
    this.#menuItems = menuItems;
    this.#targetTable = targetTable;
    this.#options = options;
  }

  get hasAddItems() {
    return this.#options.add_items !== false;
  }

  perform() {
    console.group(
      `Performing mapping ${this.#sourceName} -> ${this.#targetTable.name}`,
    );

    const candidates = this.#menuItems.map(
      (menuItem) => new SourceMappingCandidate(menuItem),
    );

    // The first time we look through the menu for matches only.
    console.group("Adding matching candidates.");
    this.addMatchingCandidates(candidates);
    console.groupEnd();

    console.group("Adding remaining items.");
    this.addCandidates(candidates.filter((candidate) => !candidate.isAdded));
    console.groupEnd();

    this.#targetTable.write();
    console.groupEnd();
  }

  addMatchingCandidates(candidates) {
    candidates.forEach((candidate) => {
      const menuItem = candidate.menuItem;
      const matchingRow = this.#targetTable.matchingRow(menuItem.name);

      if (!matchingRow) {
        console.debug(`❌ "${menuItem.name}": no match.`);
        return;
      }

      console.debug(
        `🔄 "${menuItem.name}": matched "${matchingRow.name}". Overwriting.`,
      );
      // We want to preserve the name on the target, since it's likely the canonical
      // form. The matched form may not be correct.
      matchingRow.updateFromMenuItem(menuItem, { preserve: [NAME_KEY] });
      return candidate.added();
    });
  }

  addCandidates(candidates) {
    candidates.forEach((candidate) => {
      const menuItem = candidate.menuItem;
      // first we try to overwrite an existing row that hasn't been locked copyAsTemplate
      const row = this.#targetTable.unlockedProducts[0];
      if (row) {
        console.debug(
          `🔄 "${menuItem.name}": found "${row.name}". Overwriting`,
        );
        row.updateFromMenuItem(menuItem);
        return candidate.added();
      }

      if (!this.hasAddItems) return;

      // Add a new row for it
      console.debug(`🆕 "${menuItem.name}": no rows left. Adding.`);
      this.#targetTable.addFromMenuItem(menuItem);
      return candidate.added();
    });
  }
}

class DietaryPreferences {
  constructor(vegan, vegetarian, halal, kosher) {
    this.vegan = vegan;
    this.vegetarian = vegetarian;
    this.halal = halal;
    this.kosher = kosher;
  }
}

class SourceMenu {
  static fromRaw(json) {
    const menuItems = JSON.parse(json).map((item) => MenuItem.fromRaw(item));

    return new SourceMenu(menuItems);
  }

  constructor(menuItems) {
    this.menuItems = menuItems;
  }

  whereCategory(category) {
    return this.menuItems.filter((item) => item.category === category);
  }
}

class MenuItem {
  static fromRaw({
    vegan,
    vegetarian,
    halal,
    kosher,
    name,
    price,
    description,
    category,
    alcohol_percentage,
  }) {
    const dietaryPreferences = new DietaryPreferences(
      vegan,
      vegetarian,
      halal,
      kosher,
    );

    return new MenuItem(
      name,
      price,
      description,
      category,
      dietaryPreferences,
      alcohol_percentage,
    );
  }

  constructor(
    name,
    price,
    description,
    category,
    dietary_preferences,
    alcohol_percentage,
  ) {
    this.name = name;
    this.price = price;
    this.description = description;
    this.category = category;
    this.dietaryPreferences = dietary_preferences;
    this.alcohol_percentage = alcohol_percentage;
  }
}

// PrintessTable represents a special kind of printess field which is a table.
// A table has rows.
class PrintessTable {
  #printessCtx;
  #field;
  #rows;

  constructor(printessCtx, name, field) {
    this.name = name;

    this.#printessCtx = printessCtx;
    this.#field = field;
    this.#rows = JSON.parse(this.#field.value).map(
      (row) => new PrintessRow(row),
    );
  }

  static find(printessCtx, name) {
    const raw = printessCtx.printessFieldFromName(name);
    if (!raw) return;

    return new PrintessTable(printessCtx, name, raw);
  }

  get unlockedProducts() {
    return this.#products.filter((product) => product.isUnlocked);
  }

  // A "matching row" is that which is both unlocked and fuzzy matches on name.
  matchingRow(name) {
    return this.unlockedProducts.find((product) => product.matches(name));
  }

  addFromMenuItem(menuItem) {
    const row = this.#templateRow;
    if (!row) {
      console.error(`No template row found for ${this.name}`);
      return;
    }
    row.updateFromMenuItem(menuItem);

    this.#add(row);
  }

  get #templateRow() {
    return this.#products[0]?.copyAsTemplate();
  }

  get #products() {
    return this.#rows.filter((row) => row.isProduct);
  }

  #add(row) {
    this.#rows.push(row);
  }

  write() {
    console.debug(`Writing table to ${this.name}`);

    this.#printessCtx.editor.api.setFormFieldValue(
      this.name,
      JSON.stringify(this.#rows),
    );
  }
}

// A PrintessRow belongs to a PrintessTable, and represents a single level key/value store.
// It provides a wrapper around a raw row object, which is its canonical representation and is always
// kept up-to-date.
class PrintessRow {
  #row;
  #isLocked;

  constructor(row, isLocked = false) {
    this.#row = row;

    // Row may not be changed after it is locked.
    // Rows are unlocked if they are part of the default template.
    // Once they are modified, they are locked.
    // Any added rows are also locked.
    this.#isLocked = isLocked;
  }

  get isUnlocked() {
    return !this.#isLocked;
  }

  get isProduct() {
    return this.#kind === PRODUCT_KIND;
  }

  matches(name) {
    return Util.fuzzyMatch(name, this.name);
  }

  copyAsTemplate() {
    return new this.constructor({ ...this.#row, name: null, show: false });
  }

  toJSON() {
    return this.#row;
  }

  updateFromMenuItem(menuItem, { preserve = [] } = {}) {
    // these should never be mapped from the menu
    const preservedAttributes = [KIND_KEY, SHOW_KEY, ...preserve];

    for (const attr in menuItem) {
      if (preservedAttributes.includes(attr)) continue;

      this.#updateAttrFromMenuItem(attr, menuItem);
    }

    // Don't allow updates to this after it has been updated.
    this.#isLocked = true;
  }

  get name() {
    return this.#row[NAME_KEY];
  }

  get #kind() {
    return this.#row[KIND_KEY];
  }

  #updateAttrFromMenuItem(attr, menuItem) {
    switch (attr) {
      case DIETARY_PREFERENCES_KEY:
        this.#updateDietaryPreferences(menuItem[attr]);
        break;
      case NAME_KEY:
        this.#updateNameFromMenuItem(menuItem[attr]);
        break;
      case PRICE_KEY:
        this.#updatePriceFromMenuItem(menuItem[attr]);
        break;
      case DESCRIPTION_KEY:
        this.#updateDescriptionFromMenuItem(menuItem[attr]);
        break;
      default:
        this.#updateTypedAttrFromMenuItem(attr, menuItem);
        break;
    }
  }

  #updateDietaryPreferences(dietaryPreferences) {
    // They are all booleans
    for (const attr in dietaryPreferences) {
      this.#updateBooleanFromMenuItem(attr, dietaryPreferences[attr]);
    }
  }

  #updateNameFromMenuItem(menuItemValue) {
    this.#row[NAME_KEY] = menuItemValue;
  }

  #updatePriceFromMenuItem(menuItemValue) {
    this.#row[PRICE_KEY] = Util.formatPrice(menuItemValue);
  }

  #updateDescriptionFromMenuItem(menuItemValue) {
    // Description is a reserved keyword in the json schema.
    this.#row[DESCRIPTION_KEY] = menuItemValue;
  }

  #updateTypedAttrFromMenuItem(attr, menuItem) {
    const rowValue = this.#row[attr];

    switch (typeof rowValue) {
      case "boolean":
        this.#updateBooleanFromMenuItem(attr, menuItem[attr]);
        break;
      case "number":
        this.#updateNumberFromMenuItem(attr, menuItem[attr], rowValue);
        break;
      default:
        if (menuItem[attr] !== undefined) this.#row[attr] = menuItem[attr];
        break;
    }
  }

  #updateBooleanFromMenuItem(attr, menuItemValue) {
    this.#row[attr] = Util.formatBoolean(menuItemValue);
  }

  #updateNumberFromMenuItem(attr, menuItemValue, rowValue) {
    if (Number.isInteger(rowValue)) {
      this.#row[attr] = Util.formatInt(menuItemValue);
    } else {
      this.#row[attr] = Util.formatFloat(menuItemValue);
    }
  }
}

class Util {
  static fuzzyMatch(a, b) {
    const aNormalized = this.normalizeName(a);
    const bNormalized = this.normalizeName(b);

    return aNormalized === bNormalized;
  }

  static normalizeName(name) {
    if (!name) return "";

    return name
      .replaceAll(/[-_\s]/g, "")
      .trim()
      .toLowerCase();
  }

  static formatInt(int) {
    return parseInt(int) || 0;
  }

  static formatFloat(float) {
    return parseFloat(float) || 0.0;
  }

  static formatPrice(price) {
    if (!price) {
      return null;
    }

    const num = parseFloat(price);

    // TODO: We probably want this settable via metadata. It dictates the price format.
    // Format the number to 2 decimal places using the user's locale
    return num.toLocaleString(LOCALE_FOR_PRICE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static formatBoolean(boolean) {
    return !!boolean;
  }
}

export const SourceMenuMixin = (Base) =>
  class extends Base {
    pushSourceMenu() {
      console.debug("Pushing source menu.")
      new MappingOrchestrator(this).perform();
    }
  };
